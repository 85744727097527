import React, { useEffect, useMemo, useState, Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  SplitTestParams,
  Test,
} from 'pages/articles/SplitTest/models/CreateSplitTestParams';
import ActiveTests from 'pages/articles/SplitTest/components/ActiveTests';
import AddNewTest from 'pages/articles/SplitTest/components/AddNewTest';
import ArticlesService from 'services/ArticlesService';
import RoundedPaper from 'shared/components/RoundedPaper';
import UpdateButton from 'shared/components/UpdateButton';
import { makeValidURL } from 'utils/transformURL';

const articleService = new ArticlesService();

const newExperiment = {
  experiments: [
    { url: '', weight: 50 },
    { url: '', weight: 50 },
  ],
};

function formatTests(tests: SplitTestParams[]) {
  const nonBlankTests = cloneDeep(tests).filter(
    test => test.experiments[0].url !== '',
  );

  const formattedTests = nonBlankTests.map(test => {
    const nonBlankExperiments = test.experiments.filter(
      experiment => experiment.url !== '',
    );

    nonBlankExperiments.forEach(experiment => {
      const formattedURL = makeValidURL(experiment.url);
      const url = new URL(formattedURL);
      experiment.url = formattedURL.replace(/\/$/, '');
      experiment.weight = experiment.weight / 100;
      experiment.cfTriggerKey = url.pathname.replaceAll('/', '');
    });

    test.experiments = nonBlankExperiments;
    return test;
  });

  return formattedTests;
}

function SplitTest() {
  const [tests, setTests] = useState<SplitTestParams[]>([
    cloneDeep(newExperiment),
  ]);
  const [activeTests, setActiveTests] = useState<SplitTestParams[]>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    getActiveTests();
  }, []);

  const activeTestsTable = useMemo(
    () => (
      <RoundedPaper sx={{ mt: 3, p: 4, width: '55vw' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h5">Active Tests</Typography>
        </Box>
        <ActiveTests
          tests={activeTests?.map(test => {
            return { ...test, id: uuid() };
          })}
          onRemoveTestGroups={groups => handleRemoveTestGroups(groups)}
          loading={removeLoading}
        />
      </RoundedPaper>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTests, removeLoading],
  );

  const getActiveTests = async () => {
    const tests = await articleService.getActiveSplitTests();
    if (tests) setActiveTests(tests);
  };

  const handleUpdateTests = (idx: number, updates: Test[]) => {
    const updatedTests = [...tests];
    updatedTests[idx] = { experiments: updates };
    setTests(updatedTests);
  };

  const handleAddNewTestGroup = () => {
    setTests([...tests, cloneDeep(newExperiment)]);
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    const formattedTests = formatTests(tests);
    const resp = await articleService.addSplitTests({ tests: formattedTests });
    if (resp !== null) {
      setTests([cloneDeep(newExperiment)]);
      getActiveTests();
    }

    setSubmitLoading(false);
  };

  const handleRemoveTestGroups = async (testGroups: SplitTestParams[]) => {
    setRemoveLoading(true);

    const formattedTestGroups = testGroups;
    formattedTestGroups.forEach((_group, idx) => {
      delete formattedTestGroups[idx].id;
    });

    const resp = await articleService.deleteSplitTests({
      tests: formattedTestGroups,
    });
    if (resp !== null) {
      getActiveTests();
    }

    setRemoveLoading(false);
  };

  return (
    <Fragment>
      <Typography variant="h3" color={'secondary.main'} align="center">
        A/B Tests
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <RoundedPaper sx={{ p: 4, width: '55vw' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Typography variant="h5">Add New Tests</Typography>
          </Box>
          {tests.map((test, idx) => (
            <Box key={`add-test-${idx}`} sx={{ mt: 2 }}>
              <Typography variant="button">Test Group {idx + 1}:</Typography>
              <AddNewTest
                experiments={test.experiments}
                onTestsUpdate={updates => handleUpdateTests(idx, updates)}
              />
            </Box>
          ))}
          <Stack spacing={1} alignItems="center" sx={{ mt: 3 }}>
            <UpdateButton
              variant="outlined"
              color="secondary"
              onClick={handleAddNewTestGroup}
              sx={{ width: '15%' }}
            >
              Add Group
            </UpdateButton>
            <UpdateButton
              variant="contained"
              loading={submitLoading}
              onClick={handleSubmit}
              sx={{ width: '15%' }}
            >
              Create Tests
            </UpdateButton>
          </Stack>
        </RoundedPaper>
        {activeTestsTable}
      </Box>
    </Fragment>
  );
}

export default SplitTest;
