import React, { Fragment, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Box from '@mui/material/Box';

import { DashboardService } from 'services';
import DateDropdown from 'pages/Dashboard/components/dateRangeSelection/DateDropdown';
import DateRangeSelector from 'pages/Dashboard/components/dateRangeSelection/DateRangeSelector';

interface DateRangeProps {
  dashboardService: DashboardService;
}

function DateRange({ dashboardService }: DateRangeProps) {
  const [startDate, setStartDate] = useState<Dayjs>(
    dashboardService.startDate || dayjs(),
  );
  const [endDate, setEndDate] = useState<Dayjs>(
    dashboardService.endDate || dayjs(),
  );
  const [presetRange, setPresetRange] = useState('yesterday');

  if (startDate && endDate) {
    if (startDate <= endDate) {
      dashboardService.startDate = startDate;
      dashboardService.endDate = endDate;
    }
  }

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box mt={1} sx={{ width: 250 }}>
          <DateDropdown
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            presetRange={presetRange}
            onPresetRangeUpdate={(range: string) => setPresetRange(range)}
          />
        </Box>
        <Box mb={1}>
          <DateRangeSelector
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            onPresetRangeUpdate={(range: string) => setPresetRange(range)}
          />
        </Box>
      </Box>
    </Fragment>
  );
}

export default DateRange;
