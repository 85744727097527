import { CustomCampaignParams } from 'pages/Creator/models/FacebookCustomCampaignParams';
import { FacebookLinkCampaign } from 'pages/Creator/models/FacebookLinkCampaign';
import { compressImage, imageToHash } from 'utils/formatImages';

export const LOWEST_COST_BID_STRATEGY = 'LOWEST_COST_WITHOUT_CAP';

export const distributeAdAccount = {
  name: 'Multiple',
  id: 'Distribute',
};

export const lowestCostAdAccounts = [
  {
    name: '[SBLY] Invoicing 35',
    id: 'act_408868689933277',
  },
  // {
  //   name: '[SBLY] Invoicing 36',
  //   id: 'act_403385230211454',
  // },
];

export async function makeCreationParams(campaigns: FacebookLinkCampaign[]) {
  const creationParams: CustomCampaignParams[] = [];
  const completeCampaigns = campaigns.filter(
    campaign => campaign.link !== '' && campaign.selectedTemplates.length > 0,
  );

  for (const campaign of completeCampaigns) {
    const compressedImages = await Promise.all(
      campaign.images.map(async image => await compressImage(image)),
    );
    const imagesHash = await imageToHash(compressedImages);

    const campaignParams = {
      batch: campaign.batch,
      link: campaign.link,
      domain: campaign.linkData!.domain,
      slug: campaign.linkData!.slug,
      body: campaign.linkData!.caption,
      title: campaign.linkData!.title,
      images: imagesHash,
    };

    const standardCreation = {
      ...campaignParams,
      adAccountId: campaign.selectedAdAccount.id,
      audiences: campaign.templates.filter(template => {
        return (
          campaign.selectedTemplates.includes(template.id!) &&
          template.bidStrategy !== LOWEST_COST_BID_STRATEGY
        );
      }),
    };
    creationParams.push(standardCreation);

    const lowestCostCreation = {
      ...campaignParams,
      adAccountId: campaign.selectedLCAdAccount.id,
      audiences: campaign.templates.filter(template => {
        return (
          campaign.selectedTemplates.includes(template.id!) &&
          template.bidStrategy === LOWEST_COST_BID_STRATEGY
        );
      }),
    };
    if (lowestCostCreation.audiences.length > 0)
      creationParams.push(lowestCostCreation);
  }

  return creationParams;
}
