import React, { useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import {
  arrayContainsArray,
  nestedItemsArrayFromArray,
  removeArrayFromArray,
} from 'utils/transformArray';
import { FACEBOOK_DATA_SOURCE } from 'pages/Dashboard/utils/constants';
import {
  FacebookLinkCampaign,
  FB_CAMPAIGN_PARAMS,
} from 'pages/Creator/models/FacebookLinkCampaign';
import {
  formatTemplates,
  groupTemplatesByHeaders,
  getSelectedTemplates,
} from 'pages/Creator/utils/templates';
import { FacebookCreationTemplate } from 'pages/Creator/models/FacebookCreationTemplate';
import { generatePaths } from 'utils/transformObj';
import ArticlesService from 'services/ArticlesService';
import AdAccount from 'pages/Creator/models/AdAccount';
import AdAccounts from 'pages/Creator/components/facebook/AdAccounts';
import ButtonPopover from 'shared/components/ButtonPopover';
import CustomForm from 'pages/Creator/components/facebook/CustomForm';
import FacebookCreatorService from 'services/FacebookCreatorService';
import GroupSelection from 'pages/Creator/components/facebook/GroupSelection';
import ImagesNamesList from 'shared/components/ImagesNamesList';
import SelectImages from 'shared/components/SelectImages';
import URLForm from 'pages/Creator/components/facebook/FacebookURLForm';

export const facebookCreatorService = new FacebookCreatorService();
const articlesService = new ArticlesService();

interface CreateCampaignFromLinkParams {
  adAccounts: AdAccount[];
  lowestCostAdAccounts: AdAccount[];
  selectedCampaign: FacebookLinkCampaign;
  selectedCampaignIdx: number;
  onCampaignUpdate: (
    updates: { key: keyof FacebookLinkCampaign; value: any }[],
  ) => void;
}

function CreateCampaignFromLink({
  adAccounts,
  lowestCostAdAccounts,
  selectedCampaign,
  selectedCampaignIdx,
  onCampaignUpdate,
}: CreateCampaignFromLinkParams) {
  const [linkLoading, setLinkLoading] = useState(false);

  const unselectedImages = selectedCampaign.savedImages.filter(
    image => !selectedCampaign.images.includes(image),
  );

  const fetchTemplates = async () => {
    setLinkLoading(true);

    const [images, linkData, templates] = await Promise.all([
      articlesService.getImages<File[]>({ link: selectedCampaign.link }),
      facebookCreatorService.getLinkInfo(selectedCampaign.link),
      facebookCreatorService.getTemplates(FACEBOOK_DATA_SOURCE),
    ]);

    const articleType = await facebookCreatorService.getArticleType(
      linkData.domain,
      linkData.slug,
    );

    const formattedTemplates = formatTemplates(
      templates.templates,
      linkData,
      articleType,
    );
    const templateGroups = groupTemplatesByHeaders(formattedTemplates);

    onCampaignUpdate([
      { key: FB_CAMPAIGN_PARAMS.templates, value: formattedTemplates },
      { key: FB_CAMPAIGN_PARAMS.selectedTemplates, value: [] },
      { key: FB_CAMPAIGN_PARAMS.groupedTemplates, value: templateGroups },
      { key: FB_CAMPAIGN_PARAMS.selectedGroups, value: [] },
      { key: FB_CAMPAIGN_PARAMS.linkData, value: linkData },
      {
        key: FB_CAMPAIGN_PARAMS.savedImages,
        value: images,
      },
    ]);
    setLinkLoading(false);
  };

  const handleTemplateUpdate = (
    id: string,
    field: keyof FacebookCreationTemplate,
    newValue: string,
  ) => {
    const updatedTemplates = selectedCampaign.templates.map(template => {
      if (template.id === id) {
        return {
          ...template,
          [field]: newValue,
        };
      }
      return template;
    });
    onCampaignUpdate([
      { key: FB_CAMPAIGN_PARAMS.templates, value: updatedTemplates },
    ]);
  };

  const handleSelectedTemplatesUpdate = (newValue: string) => {
    const updatedTemplates = [...selectedCampaign.templates];
    updatedTemplates.forEach(template => {
      if (selectedCampaign.selectedTemplates.includes(template.id)) {
        template.campaignName = `${template.ogCampaignName} ${newValue}`;
      }
    });

    onCampaignUpdate([
      { key: FB_CAMPAIGN_PARAMS.campaignNameAppend, value: newValue },
      { key: FB_CAMPAIGN_PARAMS.templates, value: updatedTemplates },
    ]);
  };

  const handleCheckboxToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    let newSelectedTemplates;
    if (event.target.checked) {
      newSelectedTemplates = [...selectedCampaign.selectedTemplates, id];
    } else {
      newSelectedTemplates = selectedCampaign.selectedTemplates.filter(
        templateID => templateID !== id,
      );
    }

    onCampaignUpdate([
      {
        key: FB_CAMPAIGN_PARAMS.selectedTemplates,
        value: newSelectedTemplates,
      },
    ]);
  };

  const handleSelectedGroupsChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    newGroups: string[],
  ) => {
    const changedGroups = generatePaths(
      newGroups,
      selectedCampaign.groupedTemplates,
    );
    const changedTemplates = getSelectedTemplates(
      newGroups,
      selectedCampaign.groupedTemplates,
    );

    let currSelectedGroups = [...selectedCampaign.selectedGroups];
    let currSelectedTemplates = [...selectedCampaign.selectedTemplates];

    if (arrayContainsArray(selectedCampaign.selectedGroups, newGroups)) {
      const parents: string[][] = nestedItemsArrayFromArray(newGroups);
      currSelectedGroups = removeArrayFromArray(currSelectedGroups, [
        ...changedGroups,
        ...parents,
      ]);

      currSelectedTemplates = currSelectedTemplates.filter(
        template => !changedTemplates.includes(template),
      );
    } else {
      currSelectedGroups.push(...changedGroups);
      currSelectedTemplates.push(...changedTemplates);
    }

    onCampaignUpdate([
      {
        key: FB_CAMPAIGN_PARAMS.selectedGroups,
        value: currSelectedGroups,
      },
      {
        key: FB_CAMPAIGN_PARAMS.selectedTemplates,
        value: currSelectedTemplates,
      },
    ]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          mt: 1,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'end', mb: -1 }}>
          <FormControlLabel
            control={
              <Switch
                checked={selectedCampaign.batch}
                onChange={() =>
                  onCampaignUpdate([
                    {
                      key: FB_CAMPAIGN_PARAMS.batch,
                      value: !selectedCampaign.batch,
                    },
                  ])
                }
              />
            }
            label="Batch"
            sx={{ textTransform: 'uppercase' }}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '100%', pr: 1 }}>
            <URLForm
              url={selectedCampaign.link}
              onURLUpdate={url =>
                onCampaignUpdate([{ key: FB_CAMPAIGN_PARAMS.link, value: url }])
              }
              size="small"
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LoadingButton
              disabled={selectedCampaign.link === ''}
              color="secondary"
              loading={linkLoading}
              variant="outlined"
              loadingPosition="end"
              endIcon={<BorderColorIcon />}
              onClick={() => {
                fetchTemplates();
              }}
            >
              templates
            </LoadingButton>
          </Box>
        </Box>
        <AdAccounts
          id="ad-account-selection"
          label="Select Ad Account"
          selected={selectedCampaign.selectedAdAccount}
          adAccountOptions={adAccounts}
          onAccountUpdate={newAccount =>
            onCampaignUpdate([
              { key: FB_CAMPAIGN_PARAMS.selectedAdAccount, value: newAccount },
            ])
          }
        />
        <AdAccounts
          id="lc-ad-account-selection"
          label="Select Lowest Cost Ad Account"
          selected={selectedCampaign.selectedLCAdAccount}
          adAccountOptions={lowestCostAdAccounts}
          onAccountUpdate={newAccount =>
            onCampaignUpdate([
              {
                key: FB_CAMPAIGN_PARAMS.selectedLCAdAccount,
                value: newAccount,
              },
            ])
          }
        />
        {unselectedImages.length > 0 && (
          <ButtonPopover label="Additional Images">
            <ImagesNamesList
              images={unselectedImages}
              onImageClick={image =>
                onCampaignUpdate([
                  {
                    key: FB_CAMPAIGN_PARAMS.images,
                    value: [...selectedCampaign.images, image],
                  },
                ])
              }
            />
          </ButtonPopover>
        )}
        <SelectImages
          key={`select-image-${selectedCampaignIdx}`}
          images={selectedCampaign.images}
          onChange={newImages => {
            onCampaignUpdate([
              { key: FB_CAMPAIGN_PARAMS.images, value: newImages },
            ]);
          }}
        />
        {selectedCampaign.templates.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              id="update-campaign-name"
              label="Add to campaign name"
              value={selectedCampaign.campaignNameAppend}
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleSelectedTemplatesUpdate(event.target.value);
              }}
              sx={{ mb: 1 }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <GroupSelection
                groupedTemplates={selectedCampaign.groupedTemplates}
                selectedGroups={selectedCampaign.selectedGroups}
                onSelectedUpdate={(type, newValues) =>
                  handleSelectedGroupsChange(type, newValues)
                }
              />
              <Typography variant="caption" sx={{ ml: 1 }}>
                {selectedCampaign.selectedTemplates.length} selected
              </Typography>
            </Box>
            {selectedCampaign.templates.map(template => {
              return (
                <Box
                  key={template.id}
                  sx={{ display: 'flex', alignItems: 'flex-start', mt: 3 }}
                >
                  <Checkbox
                    checked={selectedCampaign.selectedTemplates?.includes(
                      template.id!,
                    )}
                    onChange={event =>
                      handleCheckboxToggle(event, template.id!)
                    }
                  />
                  <CustomForm
                    template={template}
                    onFormUpdate={(field, newValue) => {
                      handleTemplateUpdate(template.id!, field, newValue);
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default CreateCampaignFromLink;
