import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import timezone from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(utcPlugin);
dayjs.extend(timezone);

export function formatDate(date: Date | string) {
  return moment(date).format('YYYY-MM-DD');
}

export function formatDayjs(date: Dayjs) {
  return date.format('YYYY-MM-DD');
}

export function formatDateTime(date: Date | string) {
  return moment(date).format('YYYY-MM-DD HH:mm');
}

export function formatDateTimeUTC(dateTime: Dayjs) {
  return dateTime.utc().format('YYYY-MM-DDTHH:mm:ss');
}

export function formatDateTimeLocal(dateTime: Dayjs) {
  return dateTime.format('YYYY-MM-DDTHH:mm:ss');
}

export function yesterday() {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today;
}

export function yesterdayDayjs() {
  return dayjs().subtract(1, 'day');
}

export function addDays(date: Dayjs | string, days: number) {
  return dayjs(date).add(days, 'day').format('YYYY-MM-DD');
}

export function convertToPST(date: Dayjs | undefined) {
  if (date) {
    return date.tz('America/Los_Angeles');
  }
}
