import React from 'react';

import { TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { Test } from 'pages/articles/SplitTest/models/CreateSplitTestParams';

interface AddNewTestProps {
  experiments: Test[];
  onTestsUpdate: (experiments: Test[]) => void;
}

function AddNewTest({ experiments, onTestsUpdate }: AddNewTestProps) {
  const totalWeight = experiments.reduce((acc, curr) => acc + curr.weight, 0);
  const weightError = totalWeight === 100 ? '' : 'sum ≠ 100';

  const handleAddNew = () => {
    onTestsUpdate([...experiments, { url: '', weight: 0 }]);
  };

  const handleUpdate = <K extends keyof Test>(
    idx: number,
    type: K,
    value: Test[K],
  ) => {
    const updatedTests = [...experiments];
    updatedTests[idx][type] = value;
    onTestsUpdate(updatedTests);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: '100%' }}>
        {experiments.map((test, idx) => {
          const label = idx === 0 ? 'Original URL' : 'Experiment URL';
          return (
            <Box key={idx} sx={{ display: 'flex', mt: 1 }}>
              <TextField
                fullWidth
                id={`url-${idx}`}
                label={label}
                variant="outlined"
                value={test.url}
                size="small"
                inputProps={{
                  autoComplete: 'off',
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleUpdate(idx, 'url', event.target.value);
                }}
              />
              <TextField
                label="weight"
                type="number"
                value={test.weight}
                error={totalWeight !== 100}
                helperText={weightError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                size="small"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleUpdate(idx, 'weight', +event.target.value);
                }}
                sx={{ width: 100, ml: 1 }}
              />
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          width: '2%',
          display: 'flex',
          ml: 1,
          alignItems: 'end',
        }}
      >
        <IconButton onClick={handleAddNew}>
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default AddNewTest;
