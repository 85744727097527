import dayjs from 'dayjs';
import uniqWith from 'lodash/uniqWith';

import {
  defaultRowsPerPage,
  AscendingOrder,
  DescendingOrder,
  IsOnOrBeforeDateKey,
  IsOnOrAfterDateKey,
  IsOnDateKey,
  DateEST,
  DatePST,
  SumAggregationKey,
  TABOOLA_DATA_SOURCE,
  TABOOLA_REAL_TIME_DATA_SOURCE,
  OUTBRAIN_DATA_SOURCE,
} from 'pages/Dashboard/utils/constants';
import { ScalingDiagnosticsQueryData } from 'pages/Dashboard/models/ScalingDiagnostics';
import { FACEBOOK_DATA_SOURCE } from 'pages/Dashboard/utils/constants';
import { FilterColumn, Settings } from 'pages/Dashboard/models/Settings';
import {
  addDays,
  formatDate,
  formatDayjs,
  yesterdayDayjs,
} from 'utils/formatDate';
import { METRICS } from 'pages/Dashboard/utils/constants';
import { Segment } from 'pages/Dashboard/models/CustomDimension';
import { sessionStorageGet } from 'utils/sessionStorage';
import Aggregations from 'pages/Dashboard/models/Aggregations';
import BaseService from 'services/BaseService';
import Filter from 'pages/Dashboard/models/Filter';
import Order from 'pages/Dashboard/models/Order';
import PerformanceData from 'pages/Dashboard/models/PerformanceData';

export const DATA_SOURCE_KEY = 'dataSourceKey';

class DashboardService extends BaseService {
  DASHBOARD_ROUTE = 'dashboard';
  DASHBOARD_SETTINGS_ROUTE = `${this.DASHBOARD_ROUTE}/settings`;
  GRAPH_DATA_ROUTE = `${this.DASHBOARD_ROUTE}/graph-data`;
  DIAGNOSTICS_ROUTE = `${this.DASHBOARD_ROUTE}/scaler-diagnostics`;

  settings = {} as Settings;
  startDate = yesterdayDayjs();
  endDate = yesterdayDayjs();
  metrics: string[] = [];
  dimensions: string[] = [];
  customDimensions: Segment[] = [];
  filters: Filter[] = [];
  dataSource: string =
    sessionStorageGet(DATA_SOURCE_KEY) || FACEBOOK_DATA_SOURCE;
  graphDataSource: string = '';
  aggregations: Aggregations[] = [];
  orderByKey: keyof PerformanceData = METRICS.REVENUE as keyof PerformanceData;
  orderKey: Order = DescendingOrder;
  limit: number = defaultRowsPerPage;
  offset: number = 0;

  async getSettings(dataSource: string) {
    await this._getAccessToken();
    const resp = await this.http.get(this.DASHBOARD_SETTINGS_ROUTE, {
      params: { dataSources: [dataSource] },
    });
    this.settings = resp.data;
  }

  async getMetrics(
    metrics: FilterColumn[],
    dimensions: FilterColumn[],
    customDimensions: Segment[],
    dataSource: string,
  ) {
    this.dataSource = dataSource;

    this._setMetricsDimensions(metrics, dimensions, customDimensions);
    const params = this._buildParams(metrics, dimensions);
    const resp = await this.http.post(this.DASHBOARD_ROUTE, params);
    this.resetService();
    return resp.data;
  }
  async getGraphData(
    metrics: FilterColumn[],
    dimensions: FilterColumn[],
    customDimensions: Segment[],
  ) {
    this._setMetricsDimensions(metrics, dimensions, customDimensions);
    const params = this._buildParams(metrics, dimensions, true);
    const resp = await this.http.post(this.GRAPH_DATA_ROUTE, params);
    this.resetService();
    resp.data.labels = resp.data.date_pst;
    return resp.data;
  }

  async getScalingDiagnostics(query: ScalingDiagnosticsQueryData) {
    const isToday =
      formatDayjs(this.startDate) === dayjs().format('YYYY-MM-DD');

    const startOffset = isToday ? 2 : 1;
    const startDate = dayjs(this.startDate)
      .clone()
      .subtract(startOffset, 'day');
    const endDate = dayjs(this.endDate).clone().add(1, 'day');

    const params = {
      dataSource: query.dataSource,
      adsetId: query.adset_id,
      campaignId: query.campaign_id,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };

    const resp = await this.http.get(this.DIAGNOSTICS_ROUTE, { params });
    return resp.data;
  }

  resetService() {
    this.filters = [];
    this.dimensions = [];
    this.customDimensions = [];
  }

  private _setMetricsDimensions(
    metrics: FilterColumn[],
    dimensions: FilterColumn[],
    customDimensions: Segment[],
  ) {
    this.metrics = metrics.map(metric => metric.key);
    this.dimensions = dimensions
      .filter(dimension => dimension.checked)
      .map(dimension => dimension.key);
    this.customDimensions = customDimensions;
  }

  private _buildParams(
    metrics: FilterColumn[],
    dimensions: FilterColumn[],
    graphData = false,
  ) {
    this._buildAggregations();
    this._buildFilters(metrics, dimensions, graphData);

    return {
      metrics: this.metrics,
      dimensions: graphData ? [...this.dimensions, DatePST] : this.dimensions,
      customDimensions: this._buildCustomDimensions(),
      filters: this.filters,
      dataSources: graphData ? [this.graphDataSource] : [this.dataSource],
      aggregations: this.aggregations,
      orderByKeys: graphData ? [DatePST] : [this.orderByKey],
      orderKey: this.orderKey,
      // limit: this.limit,
      offset: this.offset,
    };
  }

  private _buildAggregations() {
    this.aggregations = [];

    this.metrics.forEach(metric => {
      this.aggregations.push({
        aggregatedMetricName: metric,
        aggregationKey: SumAggregationKey,
        columnKey: metric,
      });
    });
  }

  private _buildFilters(
    metrics: FilterColumn[],
    dimensions: FilterColumn[],
    graphData: boolean,
  ) {
    const formatFilters = (columns: FilterColumn[]) => {
      return columns
        .filter(column => column.filters && column.filters.length > 0)
        .map(column => {
          return column.filters!.map(filter => {
            return {
              filterKey: filter.predicate as string,
              columnKey: filter.key as string,
              value: filter.value as string | number,
            };
          });
        });
    };

    const formatTimeFilters = (dimensionFilters: Filter[]) => {
      return dimensionFilters
        .map(filter => {
          if (filter.filterKey === IsOnDateKey) {
            return [
              {
                filterKey: IsOnOrAfterDateKey,
                columnKey: filter.columnKey,
                value: filter.value,
              },
              {
                filterKey: IsOnOrBeforeDateKey,
                columnKey: filter.columnKey,
                value: addDays(filter.value as string, 1),
              },
            ];
          } else {
            return filter;
          }
        })
        .flat();
    };

    const metricFilters = formatFilters(metrics).flat();
    const dimensionFilters = formatTimeFilters(
      formatFilters(dimensions).flat(),
    );
    this.filters = [...this.filters, ...metricFilters, ...dimensionFilters];

    if (graphData) this.orderKey = AscendingOrder;
    this._buildDateFilter(graphData);
  }

  private _buildDateFilter(graphData: boolean) {
    const dateRange = dayjs(this.endDate).diff(dayjs(this.startDate), 'days');
    const dateKey = this._dateKeyForSource(this.dataSource);

    const startDate = formatDayjs(this.startDate);
    const endDate = formatDayjs(this.endDate);

    if (graphData && dateRange < 7) {
      const startDate = dayjs(this.endDate).subtract(7, 'days').toDate();
      this.filters.push(
        ...[
          {
            filterKey: IsOnOrAfterDateKey,
            columnKey: dateKey,
            value: formatDate(startDate),
          },
          {
            filterKey: IsOnOrBeforeDateKey,
            columnKey: dateKey,
            value: endDate,
          },
        ],
      );
      return;
    }

    if (startDate === endDate) {
      this.filters.push({
        filterKey: IsOnDateKey,
        columnKey: dateKey,
        value: startDate,
      });
    } else {
      this.filters.push(
        ...[
          {
            filterKey: IsOnOrAfterDateKey,
            columnKey: dateKey,
            value: startDate,
          },
          {
            filterKey: IsOnOrBeforeDateKey,
            columnKey: dateKey,
            value: endDate,
          },
        ],
      );
    }
  }

  private _dateKeyForSource(source: string) {
    switch (source) {
      case TABOOLA_DATA_SOURCE:
      case OUTBRAIN_DATA_SOURCE:
      case TABOOLA_REAL_TIME_DATA_SOURCE:
        return DateEST;
      default:
        return DatePST;
    }
  }

  private _buildCustomDimensions() {
    const formattedDimensions = this.customDimensions.map(dimension => {
      return {
        key: dimension.dimensionName,
        segments: [
          {
            name: dimension.segmentName,
            filter: {
              filterKey: dimension.filterKey,
              columnKey: dimension.columnKey,
              value: dimension.value,
            },
          },
        ],
      };
    });

    const mergedDuplicateKeySegments = uniqWith(
      formattedDimensions,
      (prev, curr) => {
        if (prev.key === curr.key) {
          curr.segments = [...curr.segments, ...prev.segments];
          return true;
        }
        return false;
      },
    );

    return mergedDuplicateKeySegments;
  }
}

export default DashboardService;
